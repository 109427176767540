@import 'assets/style/variables/_dimension.scss';

.ferry-list {
  width: 100%;
  margin: 0 0 2rem 0;
  padding: 1rem;
  list-style: none;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .5rem;

  @media (min-width: $mobile-width) {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
}