@import 'assets/style/variables/_color.scss';
@import 'assets/style/variables/_dimension.scss';

.faq-question {
  width: 100%;
  padding: 1rem;

  transition: linear 150ms;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: $mobile-width) {
    width: 60vw;
  }

  .faq-question__head {
    cursor: pointer;

    width: 100%;
    padding: .5rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    background-color: $blue;
    border-radius: 0 0 1rem 1rem;

    transition: linear 150ms;

    .faq-question__head-button {
      cursor: pointer;
      width: 2rem;
      height: 2rem;

      border-radius: 50%;
      outline: none;
      border: 2px solid white;

      background-color: transparent;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../../../assets/images/icon/white/plusIcon.svg');
    }

    .faq-question__head-title {
      margin: 0;
      font-size: 1.25rem;
      color: #ffffff;
    }
  }

  .faq-question__body {
    width: 100%;
    padding: .75rem;

    border: 2px solid $orange;
    border-top: none;

    transition: linear 150ms;
  }
}

.faq-question--active {

  .faq-question__head {
    background-color: $orange;
    border-radius: 0; 

    .faq-question__head-button {
      border: 2px solid black;
      background-image: url("../../../../assets/images/icon/black/minusIcon.svg");
    }
    
    .faq-question__head-title {
      color: black;
    }
  }
}

.enter {
  opacity: .4;
  transform: translateY(.75rem);
  transition: linear 150ms;
}

.enter-active {
  opacity: 1;
  transition: linear 150ms;
}

.exit {
  transition: linear 150ms;
}

.exit-active {
  opacity: .6;
  transform: translateY(-1rem);
  transition: linear 150ms;
}