@import 'assets/style/variables/_color.scss';
@import 'assets/style/variables/_shadow.scss';
@import 'assets/style/variables/_dimension.scss';

.menu-element {
  cursor: pointer;
  overflow: hidden;
  width: 65vw;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include main-shadow();

  transition: linear 100ms;

  @media (min-width: $mobile-width) {
    width: 13rem;
  }

  .menu-element__image {
    width: 100%;
    height: 11rem;
    object-fit: cover;
  }

  .menu-element__title {
    margin: 0;
    width: 100%;
    padding: .8rem;

    background-color: $orange;
    font-size: 1.65rem;
    text-align: center;
    color: black;
  }
}