@import 'assets/style/variables/_dimension.scss';


.page-data-menu {
  width: 100%;
  margin: 1.5rem 0 1.5rem 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.75rem;

  animation-name: scale-section-down;
  transition: linear 100ms;
  animation-duration: 500ms;

  @media (min-width: $mobile-width) {
    width: 70%;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
  }
}

@keyframes scale-section-down {
  from {
    scale: 1.1;
    opacity: .8;
  }
  to {
    scale: 1;
    opacity: 1;
  }
}