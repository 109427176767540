@import 'assets/style/variables/_dimension.scss';

.reparatur-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  @media (min-width: $mobile-width) {
    gap: 1rem;
  }
}