@import 'assets/style/variables/_color.scss';

.details {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .5rem;

  .details__add {
    width: 100%;
    padding-bottom: .5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .5rem;
  }

  .details__element {
    width: 100%;
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: .5rem;
    border-radius: .4rem;
    transition: linear 100ms;

    &:hover {
      background-color: $black;
    }
  }
}