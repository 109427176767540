@mixin desktop-nav() {
  position: relative;
  font-size: 1.35rem;
  text-align: center;
  text-decoration: none;
  color: black;

  transition: linear 100ms
}

.active {
  @include desktop-nav();

  &::before {
    content: "";

    position: absolute;
    left: 50%;
    bottom: -0.25rem;
    transform: translate(-50%, 0);
    width: 98%;
    height: .15rem;
    background-color: black;
  }
}

.inactive {
  @include desktop-nav();

  &:hover {
    &::before {
      content: "";

      position: absolute;
      left: 50%;
      bottom: -0.25rem;
      transform: translate(-50%, 0);
      width: 98%;
      height: .15rem;
      background-color: black;
      
      animation-name: border;
      animation-duration: 300ms;

    }
  }
}

@keyframes border {
  from {
    opacity: 0;
    width: 1rem;
  }
  to {
    opacity: 1;
    width: 98%;
    
  }
}