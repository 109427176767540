@import 'assets/style/variables/_color.scss';
@import 'assets/style/variables/_dimension.scss';
@import 'assets/style/variables/_border.scss';

.section {
  width: 100%;
  padding: .5rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  
  background-color: white;

  transition: linear 100ms;

  @media (min-width: $mobile-width) {
    width: 30vw;
    border-radius: $border-radious;
  }

  .section__toolbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .section__image {
    width: 100%;
    padding: .5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .5rem;

    background-color: $light-gray;
    border-radius: $border-radious;
    @include white-border(1px);

    h1 {
      width: 100%;
      margin: 0;
      font-size: 1rem;
      font-weight: 400;
      color: $washy-white;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: $border-radious;
    }
  }
}

.section--active {
  background-color: $light-gray;
  @include white-border(1px);
  border-radius: $border-radious;
  h1 {
    color: white;
  }
}