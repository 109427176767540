@import 'assets/style/variables/color';
@import 'assets/style/variables/_dimension.scss';
@import 'assets/style/variables/_border.scss';

.logo-page {
  width: 100%;
  min-height: 100vh;
  padding-top: $admin-header-size;

  background-color: $black;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;

  .logo-page__logo {
    width: 100%;
    padding: 1rem .5rem;
    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 2rem;

    background-color: rgb(255, 255, 255);

    @media (min-width: $mobile-width) {
      width: 35vw;
      border-radius: $border-radious;
    }

    .logo-page__logo-logo {
      overflow: hidden;
      height: 100%;
      @include balck-border(2px); 
      border-radius: $border-radious;
    }

    h1 {
      margin: 0;
    }
  }

  .logo-page__form {
    width: 100%;
    padding: 1rem .5rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;

    background-color: white;

    @media (min-width: $mobile-width) {
      width: 35vw;
      border-radius: $border-radious;
    }

    h1 {
      margin: 0;
    }
  }
}