@import 'assets/style/variables/color';
@import 'assets/style/variables/_dimension.scss';

.slider-page {
  width: 100%;
  min-height: 100vh;
  padding-top: $admin-header-size;

  background-color: $black;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;

  .slider-page__data {
    width: 100%;
    margin-top: .5rem;
    margin-bottom: 3rem;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: $mobile-width) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  

  .slider-page__info {
    color: white;
  }

  .slider-page__select {
    width: 80%;

    @media (min-width: $mobile-width) {
      z-index: 5;
      width: 20vw;
    }
  }
}