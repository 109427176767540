@import 'assets/style/variables/_dimension.scss';
@import 'assets/style/variables/_border.scss';
@import 'assets/style/variables/_shadow.scss';

.message {
  width: 100%;
  padding: 1rem;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .75rem;

  background-color: white;

  transition: linear 100ms;

  @media (min-width: $mobile-width) {
    width: 40vw;
    border-radius: $border-radious;

    &:hover {
      scale: 1.025;
      @include main-shadow();
    }
  }

  .message__title {
    margin: 0;

    span {
      font-weight: 300;
    }
  }

  .message__toolbar {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }
}