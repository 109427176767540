@import '../../variables/border';
@import '../../variables/color';

@mixin main-select-input() {
  width: 100%;
  height: 2.5rem;
  order: 2;

  background-color: $light-gray;
  outline: none;
  border: none;
  border-radius: .4rem;

  font-size: $unfocused-font-size;
  color: white;

  transition: linear 100ms;

  &:focus{
    background-color: $gray;
  }
}