@import 'assets/style/variables/_dimension.scss';
@import 'assets/style/variables/_border.scss';
@import 'assets/style/variables/_shadow.scss';

.admin-link {
  width: 100%;
  height: 7rem;

  display: flex;
  align-items: center;
  justify-content: center;
  
  background-color: white;
  background-image: url('../../../../../assets/images/icon/black/penIcon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% 80%;

  color: black;
  text-decoration: none;

  transition: linear 100ms;

  @media (max-width: $mobile-width) {
    @include washy-white-border(2px);
    border-left: none;
    border-right: none;

    @include main-shadow();
  }

  @media (min-width: $mobile-width) {
    width: 15rem;
    height: 15rem;

    @include washy-white-border(2px);

    &:hover {
      @include main-shadow();
      scale: 1.025;
    }
  }

  h1 {
    font-size: 3rem;
    transition: linear 100ms;
    text-align: center;
    @media (min-width: $mobile-width) {
      font-size: 3.5rem;
    }
  }
}