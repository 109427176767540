@import 'assets/style/variables/_color.scss';
@import 'assets/style/variables/_border.scss';

.edit-button {
  cursor: pointer;

  width: 2.25rem;
  height: 2.25rem;

  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80% 80%;

  outline: none;
  border-radius: $border-radious;
  @include  balck-border(2px);

  translate: linear 100ms;
}