@import 'assets/style/variables/_z-index.scss';
@import 'assets/style/variables/_dimension.scss';

@mixin hamburger-line {
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.26rem;
  border-radius: 4rem;
  background-color: black;
}

.hamburger-button {
  z-index: $hamburger-button-z-index;
  position: fixed;
  top: 1rem;
  right: .5rem;

  display: flex !important;
  justify-content: center;
  align-items: center;
  
  width: 3rem;
  height: 3rem;

  vertical-align: middle;
  padding: 0.4rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  .hamburger-button__box {
      position: relative;
      width: 1.9rem;
      height: 1.4rem;
  }
  &:focus {
      outline: none;
  }

  @media (min-width: $mobile-width) {
    right: 2.5rem;
    top: .5rem;
    width: 3.5rem;
    height: 3.5rem;

    background-color: rgba(255, 255, 255, 0.75) !important;
    border-radius: .4rem !important;
  }
}

.hamburger-button__line {
  @include hamburger-line;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color .1s .2s ease-in-out;

  &:before {
      @include hamburger-line;
      content: '';
      top: -10px;
      transition: transform .2s .2s ease-in-out;
  }
  &:after {
      @include hamburger-line;
      content: '';
      top: 10px;
      transition: transform .2s .2s ease-in-out;
  }
}

.hamburger-button--active .hamburger-button__line {
  background-color: transparent;
}

.hamburger-button--active .hamburger-button__line:before {
  transform: translateY(10px) rotate(45deg);
}

.hamburger-button--active .hamburger-button__line:after {
  transform: translateY(-10px) rotate(-45deg);
}