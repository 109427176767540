@import 'assets/style/variables/color';
@import 'assets/style/variables/_dimension.scss';

.page-data-page {
  width: 100%;
  min-height: 100vh;
  padding-top: $admin-header-size;
  padding-bottom: 3rem;

  background-color: $black;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.5rem;

  .page-data-page__info {
    color: white;
    font-size: 2rem;
  }

  .page-data-page__content {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1.5rem;

    @media (min-width: $mobile-width) {
      width: 100%;

      align-items: flex-start;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .page-data-page__select {
    width: 80%;

    @media (min-width: $mobile-width) {
      z-index: 5;
      width: 20vw;
    }
  }
}