@import 'assets/style/variables/_dimension.scss';

.kontakt-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  @media (min-width: $mobile-width) {
    gap: 1rem;
  }

  .kontakt-page__contact-data {
    width: 100%;
    margin-top: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (min-width: $mobile-width) {
      justify-content: space-evenly;
      flex-direction: row;
    }
  }
}