@import 'assets/style/variables/_color.scss';
@import 'assets/style/variables/_border.scss';

.delete-button {
  cursor: pointer;

  width: 2.25rem;
  height: 2.25rem;

  background-color: transparent;
  background-image: url('../../../assets/images/icon/red/binIconRed.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 78% 78%;

  outline: none;
  border-radius: $border-radious;
  border: 2px solid rgb(187, 2, 2);

  translate: linear 100ms;
}