@import 'assets/style/variables/border';
@import 'assets/style/variables/border';

.close-button {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;

  border-radius: $border-radious;

  background-color: transparent;
  background-image: url('../../../assets/images/icon/red/closeIconRed.svg');
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;

  @include invalid-border(1px);
}