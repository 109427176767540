@import 'assets/style/variables/_dimension.scss';

.main-page {
  width: 100%;
  min-height: 100vh;
  padding-top: 6rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: $mobile-width) {
    gap: 3rem;
  }

  .main-page__sections {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (min-width: $mobile-width) {
      justify-content: space-evenly;
      flex-direction: row;
    }
  }
}