@import '../../variables/color';
@import '../../variables/font';

@mixin main-input-container() {
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: .25rem;
  background-color: $main-input-background;

  input:focus + label {
    font-size: $unfocused-font-size;
    color: white;
  }

  textarea:focus + label {
    font-size: $unfocused-font-size;
    color: white;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px $main-input-background inset !important;
    -webkit-box-shadow: 0 0 0 1000px $main-input-background inset !important;
    -webkit-text-fill-color: white;
    background-color: transparent !important;
  }
}

@mixin main-input-focus {
  background-color: $main-input-background--focus;

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px $main-input-background--focus inset !important;
    -webkit-box-shadow: 0 0 0 1000px $main-input-background--focus inset !important;
  }
}