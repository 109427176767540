@import 'assets/style/variables/color';
@import 'assets/style/variables/_dimension.scss';

.contact-page {
  width: 100%;
  min-height: 100vh;
  padding-top: $admin-header-size;
  padding-bottom: 2rem;

  background-color: $black;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: $mobile-width) {
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
}