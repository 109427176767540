@import 'assets/style/variables/_color.scss';
@import 'assets/style/variables/_dimension.scss';
@import 'assets/style/variables/_shadow.scss';


.page-data {
  width: 100%;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .5rem;

  animation-name: opacity-up;
  transition: linear 100ms;
  animation-duration: 500ms;

  @media (min-width: $mobile-width) {
    width: 90vw;
    margin-bottom: 1rem;
    flex-direction: row;
    align-items: flex-start;
    gap: 0;
  }

  .page-data__map {
    width: 100%;
    height: 12rem;
    object-fit: cover;
    transition: linear 100ms;
    order: 2;

    @media (min-width: $mobile-width) {
      height: 22.5rem;
      width: 18vw;
      
      order: 0;
    }
  }

  .page-data__data {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: .5rem;

    @media (min-width: $mobile-width) {
      width: 50vw;
    }

    .page-data__title {
      width: 100%;
      margin: 0;
      padding: .5rem;
  
      background-color: $orange;
      font-size: 2rem;
      text-align: center;
      color: black;
      transition: linear 100ms;
  
      @media (min-width: $mobile-width) {
        width: auto;
        padding: .5rem 1.5rem;
      }
    }
  
    .page-data__text {
      margin: 0;
      text-align: center;
      font-size: 1.15rem;
  
      transition: linear 100ms;
  
      @media (min-width: $mobile-width) {
        width: 70%;
        padding: .5rem 1.5rem;
      }
    }
  
    .page-data__list {
      margin: .5rem;
      padding: 0;
      text-align: left;
      font-size: 1.15rem;
      list-style: none;
      text-align: center;
  
      .page-data__list-element {
        font-weight: bold;
      }
    }
  }
  
  .page-data__image {
    width: 100%;
    height: 12rem;
    object-fit: cover;

    transition: linear 100ms;

    @media (min-width: $mobile-width) {
      height: 22.5rem;
      width: 18vw;
      object-fit: cover;
      order: 0;
    }
  }
}

.page-data--left {
  .page-data__data {
    @media (min-width: $mobile-width) {
      order: 1;
    }
  }

  .page-data__map {
    @media (min-width: $mobile-width) {
      order: 2;
    }
  }

  .page-data__image {
    @media (min-width: $mobile-width) {
      order: 0;
    }
  }
}

.page-data--hover {
  @media (min-width: $mobile-width) {
    &:hover {
      scale: 1.015;

      .page-data__title {
        font-size: 2.1rem;
      }
      .page-data__text {
        margin: .25rem;
      }
      .page-data__image {
        height: 20.5rem;
      }
    }
  }
}

@keyframes opacity-up {
  from {
    opacity: .7;
    scale: .95;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}