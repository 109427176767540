@import 'assets/style/variables/z-index';
@import 'assets/style/variables/border';
@import 'assets/style/variables/_dimension.scss';

.main-modal {
  z-index: $main-modal-z-index;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  width: 90vw;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: white;
  border-radius: $border-radious;
  text-align: center;

  transition: ease-in 200ms;

  @media (min-width: $mobile-width) {
    min-width: 15rem;
    width: auto;
  }

  .close-button-container {
    position: absolute;
    top: .35rem;
    right: .35rem;
  }

  h1 {
    margin: 0 2rem 1rem 0;
    font-size: 2rem;
  }
}

.enter {
  opacity: 0;
  transform: translate(-50%, -150%);
}

.enter-active {
  opacity: 1;
  transform: translate(-50%, -50%);
}

.exit {
  opacity: 1;
}

.exit-active {
  opacity: 0;
}