@import '../../../assets/style/input/main/main-input-container';
@import '../../../assets/style/input/main/main-input';
@import '../../../assets/style/input/main/main-label';
@import '../../../assets/style/input/main/main-select-input';
@import '../../../assets/style/input/main/main-text-area';
@import '../../../assets/style/variables/border';

$wrapper-border-size: 1px;

.wrapper {
  width: 100%;
  .input-wrapper {
    position: relative;
    overflow: hidden;
    @include main-input-container();
    padding: .5rem;
  
    border-radius: $border-radious;
    @include white-border($wrapper-border-size);
  
    transition: linear 100ms;

    select {
      @include main-select-input();
    }
  
    label {
      @include main-label();
    }
  
    input {
      @include main-input();
    }

    textarea {
      @include main-text-area();
    }
  }
  
  .input-wrapper--active {
    @include white-border($wrapper-border-size);
    @include main-input-focus();
  }
  
  .input-wrapper--invalid {
    @include invalid-border($wrapper-border-size);
  }
}
