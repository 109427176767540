@import '../../../assets/style/variables/color';
@import '../../../assets/style/button/main-button';

.main-button {
  width: 100%;
  
  @include main-button();

  &:disabled {
    cursor: not-allowed;
    background-color: $washy-white;
  }
}