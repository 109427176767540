@import 'assets/style/variables/_color.scss';
@import 'assets/style/variables/_dimension.scss';


.footer {
  width: 100%;
  padding: 1rem 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  background-color: $coffe-dark;
  border-top: 1px solid $coffe;

  .footer-content {
    width: 100%;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: $mobile-width) {
      flex-direction: row;
      gap: 5vw;
    }

    .footer__socials {
      width: 100%;
  
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: .75rem;

      @media (min-width: $mobile-width) {
        width: initial;
      }
    }

    .footer__logo {
      width: 100%;
      height: 6rem;

      @media (min-width: $mobile-width) {
        width: 18rem;
        height: 7rem;
      }
    }
  
    .footer__contact {
      width: 100%;
  
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: .75rem;

      @media (min-width: $mobile-width) {
        width: initial;
      }
    }
  }

  

  .footer__copyright {
    border: 1px solid $coffe-light-dark;
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .1rem;

    font-size: .85rem;
    text-decoration: none;
    color: $light-gray;

    .footer__copyright-text {
      margin-bottom: .35rem;
    }

    a {
      text-decoration: none;
      color: $light-gray;
      cursor: pointer;
    }
  }
}