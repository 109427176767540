@import 'assets/style/variables/_color.scss';
@import 'assets/style/variables/_dimension.scss';

.ferry-element {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  @media (min-width: $mobile-width) {
    width: 25rem;
  }

  .ferry-element__title {
    width: 100%;
    margin: 0;
    padding: .5rem 1rem;
    font-size: 1.4rem;
    background-color: $orange;
    text-align: center;
  }

  .ferry-element__hours {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .ferry-element__hours-hour {
      width: 100%;
      padding: 1rem;
      font-size: 1.1rem;
      border: 2px solid $orange;
      border-top: none;
      text-align: center;
    }

    .ferry-element__hours-hour--active {
      width: 100%;
      padding: 1rem;
      font-size: 1.1rem;
      border: 2px solid $orange;
      border-top: none;
      text-align: center;
    }
  }
}