.scroll-top-button {
  cursor: pointer;
  opacity: .6;
  
  position: fixed;
  bottom: 1rem;
  right: 1.25rem;

  width: 4rem;
  height: 4rem;

  display: blocks;

  background-image: url('../../../assets/images/icon/black/triangleArrow.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  border: none;
  outline: none;
}