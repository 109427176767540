$black: #121212;

$gray: #202020;
$light-gray: #353535;

$washy-white: #b9b9b9;

$red: #d22d2e;
$dark-red: #a10e0e;

$coffe: #b8aa9d;
$coffe-dark: #9e9389;
$coffe-light-dark: #a99b8f;

$orange: #ef7d00;

$blue: #67c8f1;

// ELEMENTS
$main-input-background: $light-gray;
$main-input-background--focus: $gray;