@import 'assets/style/variables/_dimension.scss';

.message-text {
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .25rem;

  text-align: center;

  .message-text__title {
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: .5rem;

    .message-text__title-copy {
      width: 1rem;
      height: 1rem;

      display: block;
      background-image: url('../../../../../assets/images/icon/black/copyIconBlack.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      transition: linear 100ms;

      @media (min-width: $mobile-width) {
        &:hover {
          scale: 1.1;
        }
      }
    }
  }

  .message-text__text {
    margin: 0;
  }
}