@import 'assets/style/variables/_dimension.scss';

.contact-data {
  width: 100%;
  padding: 1rem;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;

  animation-name: opacity-up;
  transition: linear 100ms;
  animation-duration: 500ms;

  @media (min-width: $mobile-width) {
    width: 30rem;
  }

  .contact-data__socials {
    width: 100%;
    margin-top: .5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
}

@keyframes opacity-up {
  from {
    opacity: .85;
    scale: .95;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}