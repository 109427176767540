@import 'assets/style/variables/_dimension.scss';

.create-slide {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 4rem;

  .create-slide__button {
    width: 80%;

    @media (min-width: $mobile-width) {
      width: 22.5vw;
    }
  }

  .create-slide__form {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: linear 200ms;
  }
}

.enter {
  opacity: 0;
}

.enter-active {
  opacity: 1;
  transition: linear 300ms;
}

.exit {
  opacity: 1;
}

.exit-active {
  opacity: 0;
  transition: linear 300ms;
}