@import '../../../../../assets/style/variables/color';
@import '../../../../../assets/style/variables/border';
@import '../../../../../assets/style/variables/shadow';
@import '../../../../../assets/style/variables/dimension';


.login-form {
  width: 100%;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .5rem;

  background-color: rgba(0, 0, 0, 0.65);
  @include main-shadow();

  @media (max-width: $mobile-width) {
    @include washy-white-border(1px);
    border-left: none;
    border-right: none;
  }

  @media (min-width: $mobile-width) {
    width: 35%;
    padding: 1rem 2rem;

    border-radius: $border-radious;
    @include washy-white-border(1px);
  }

  .login-form__logo-wrapper {
    width: 60%;
    margin-bottom: .5rem;
  }

  .login-form__error {
    color: $red;
  }
}