@import '../../variables/font';

@mixin main-input() {
  width: 100%;
  padding: .35rem;

  order: 1;

  background-color: transparent;
  border: none;
  outline: none;

  font-size: $unfocused-font-size;
  color: white;

  transition: linear 100ms;

  &:focus{
    font-size: 1.1rem;
  }
}