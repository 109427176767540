@import 'assets/style/variables/_dimension.scss';

.logout {
  cursor: pointer;
  
  height: 100%;
  width: $admin-header-button-width;

  background-color: transparent;
  border: none;
  outline: none;
  background-image: url('../../../../assets/images/icon/black/logoutIcon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}