@import 'assets/style/variables/_color.scss';
@import 'assets/style/variables/_dimension.scss';
@import 'assets/style/variables/_shadow.scss';

.slider {
  width: 100%;
  margin-bottom: 2rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  animation: container-aniation 10s infinite;

  @media (min-width: $mobile-width) {
    width: 60vw;
  }

  .slider__title {
    z-index: 21;
    margin: 0;
    padding: .5rem 1rem;
    font-size: 2rem;
    background-color: $orange;
    text-align: center;
  }

  .slider__description {
    z-index: 12;
    margin: 0;
    padding: .5rem;
    background-color: white;
    text-align: center;
    border-top: 2px solid rgba(0, 0, 0, 0.2);
  }

  .slider__image {
    overflow: hidden;
    position: relative;

    width: 100%;
    height: 15rem;

    @include main-shadow();

    @media (min-width: $mobile-width){
      height: 20rem;
      
    }

    .slider__image-image {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      transition: linear 100ms;
      animation: image-aniation 10s infinite;
    }
  }
}

.slider-asd {
  

  .slider__image {
    @media (min-width: $mobile-width) {
      border-radius: .4rem !important;
    }
  }

  .slider__title {
    border-radius: .4rem .4rem 0 0;
  }
  .slider__description{
    border-radius: 0 0 .4rem .4rem;
  }
}

@keyframes image-aniation {
  0%{
  }
  5% {
    scale: 1.05;
  }
  50% {
    scale: 1;
  }
  95% {
    scale: 1.05;
  }
  100% {
  }
}

@keyframes container-aniation {
  0%{
    opacity: 0;
  }
  3%{
    opacity: .1;
  }
  8% {
    opacity: 1;
  }
  92% {
    opacity: 1;
  }
  100% {
    opacity: .1;
  }
}