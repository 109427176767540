@import 'assets/style/variables/_color.scss';
@import 'assets/style/variables/_dimension.scss';
@import 'assets/style/variables/_shadow.scss';


.page-data {
  width: 100%;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: .5rem;

  animation-name: opacity-up;
  transition: linear 100ms;
  animation-duration: 500ms;

  @media (min-width: $mobile-width) {
    width: 70vw;
    margin-bottom: 1rem;
    gap: 0;

    // &:hover {
    //   scale: 1.015;

    //   .page-data__title {
    //     font-size: 2.1rem;
    //   }
    //   .page-data__text {
    //     margin: .25rem;
    //   }
    //   .page-data__image {
    //     height: 20.5rem;
    //   }
    // }
  }

  .page-data__title {
    width: 100%;
    margin: 0;
    padding: .5rem;

    background-color: $orange;
    font-size: 2rem;
    text-align: center;
    color: black;
    transition: linear 100ms;

    @media (min-width: $mobile-width) {
      width: auto;
      padding: .5rem 1.5rem;
    }
  }

  .page-data__text {
    margin: 0;
    text-align: center;
    font-size: 1.15rem;

    transition: linear 100ms;

    @media (min-width: $mobile-width) {
      width: 70%;
      padding: .5rem 1.5rem;
    }
  }

  .page-data__list {
    margin: .5rem;
    padding: 0;
    text-align: left;
    font-size: 1.15rem;
    list-style: square;

    @media (min-width: $mobile-width) {
      width: 60%;
      padding: .5rem 1.5rem;
    }

    .page-data__list-element {
      font-weight: bold;
    }
  }

  .page-data__image {
    height: 12rem;
    margin-top: 1rem;
    object-fit: contain;

    transition: linear 100ms;

    @media (min-width: $mobile-width) {
      height: 20rem;
    }
  }
}

.page-data--hover {
  @media (min-width: $mobile-width) {
    &:hover {
      scale: 1.015;

      .page-data__title {
        font-size: 2.1rem;
      }
      .page-data__text {
        margin: .25rem;
      }
      .page-data__image {
        height: 20.5rem;
      }
    }
  }
}

@keyframes opacity-up {
  from {
    opacity: .7;
    scale: .95;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}