.contact-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: .5rem;

  color: black;

  .contact-data__image {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
  }

  .contact-data__text {
    font-size: 1.5rem;
  }
}