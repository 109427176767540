.footer-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: .3rem;

  color: rgb(0, 0, 0);

  .footer-contact__image {
    width: 1.35rem;
    height: 1.35rem;
    object-fit: contain;
  }

  .footer-contact__text {
    font-size: 1.1rem;
    text-align: center;
  }
}