@import 'assets/style/variables/z-index';
@import 'assets/style/variables/_dimension.scss';

.hamburger-modal {
  z-index: $hamburger-modal-z-index;

  position: fixed;
  top: 0;
  right: 0;

  width: 80vw;
  height: 100vh;
  padding-top: 20vh;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;

  background-color: white;
  -webkit-box-shadow: -5px 0px 10px -5px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: -5px 0px 10px -5px rgba(0, 0, 0, 0.6);
  box-shadow: -5px 0px 10px -5px rgba(0, 0, 0, 0.6);

  @media (min-width: $mobile-width) {
    width: 45vw;
  }

  .hamburger-modal__close-button {
    position: absolute;

    top: .5rem;
    right: .5rem;
  }
}

.enter {
  transform: translate(100%, 0);
}

.enter-active {
  transition: linear 200ms;
  transform: translate(0, 0);
}

.exit {
  opacity: 1;
}

.exit-active {
  opacity: 0;
  transform: translate(100%, 0);
  transition: linear 200ms;
}