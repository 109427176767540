@import 'assets/style/variables/_color.scss';
@import 'assets/style/variables/_dimension.scss';
@import 'assets/style/variables/_border.scss';

.contact {
  width: 100%;
  padding: .5rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  
  background-color: white;

  transition: linear 100ms;

  @media (min-width: $mobile-width) {
    width: 30vw;
    border-radius: $border-radious;
  }

  h1 {
    margin: 0;
  }

  .contact__toolbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.contact--active {
  background-color: $light-gray;
  @include white-border(1px);
  border-radius: $border-radious;

  h1 {
    color: white;
  }
}