@import '../../variables/border';
@import '../../variables/color';

@mixin file-picker {
  cursor: pointer;
  width: 90%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .75rem;
}

@mixin file-picker-input-wrapper {
  cursor: pointer;
  overflow: hidden;
  z-index: 2;

  position: relative;

  width: 100%;
  height: 2.8rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: $border-radious;
  background-color: $gray;
  outline: none;
  @include white-border(1px);

  text-decoration: none;
  text-align: center;
  color: white;
  font-weight: bold;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, .2);
  font-size: .9rem;

  transition: linear 100ms;  

  &:hover {
    background-color: $gray;
  }

  input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 22;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}