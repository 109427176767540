@import 'assets/style/variables/color';
@import 'assets/style/variables/_dimension.scss';

.price-page {
  width: 100%;
  min-height: 100vh;
  padding-top: $admin-header-size;

  background-color: $black;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;

  .price-page__info {
    color: white;
  }
}