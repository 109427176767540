@import 'assets/style/variables/color';
@import 'assets/style/variables/_shadow.scss';
@import 'assets/style/variables/_z-index.scss';
@import 'assets/style/variables/_dimension.scss';

.admin-header {
  z-index: $header-z-index;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: $admin-header-heigh;
  padding: $admin-header-padding;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  background-color: white;
  @include main-shadow();

  transition: linear 100ms;

  .admin-header__logo {
    height: 100%;
    width: 11rem;
  }

  .admin-header__user {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
  }
}