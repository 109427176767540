@import 'assets/style/variables/_dimension.scss';
@import 'assets/style/variables/_color.scss';

.contact-form {
  width: 100%;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .5rem;

  background-color: $gray;

  animation-name: opacity-up;
  transition: linear 100ms;
  animation-duration: 500ms;

  @media (min-width: $mobile-width) {
    width: 30vw;
    padding: 2rem;

    background-color: transparent;
    border-radius: .4rem;
  }

  .contact-form__data {
    width: 100%;
    margin-bottom: .5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .5rem;
  }

  .contact-form__contact {
    margin: .5rem;
    font-size: 2rem;
    color: white;

    @media (min-width: $mobile-width) {
      color: black;
    }
  }

  .contact-form__button {
    width: 80%;
    margin-top: 1rem;
  }

  .contact-form__delivered {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 50%;
      height: 50%;
      object-fit: contain;

      animation: scale-up 5s infinite;
    }

    span {
      font-size: 2rem;
      color: #039a03;
    }
  }
}

@keyframes opacity-up {
  from {
    opacity: .85;
    scale: 1.05;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

@keyframes scale-up {
  1% {
    scale: 1;
  }
  10% {
    scale: .85;
  }
  20% {
    scale: 1;
  }
}