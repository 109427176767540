@import './variables/font';

//Font Family: Roboto Condensed
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  
  margin: 0;
  padding: 0;
  min-width: 100vw;
  
  font-family: $main-font, sans-serif;
}