@import 'assets/style/variables/_dimension.scss';

.logo {
  width: 100%;
  height: 30vh;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $mobile-width) {
    height: 13rem;
  }

  .logo__image {
    max-width: 90%;
    height: 85%;
    object-fit: contain;

    transition: linear 100ms;
    animation-name: scale-logo;
    animation-duration: 1s;
  }
}

@keyframes scale-logo {
  from {scale: 0.85;}
  to {scale: 1;}
}