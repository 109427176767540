@import '../../variables/font';

@mixin main-text-area() {
  order: 2;
  resize: none;

  width: 100%;
  height: 100%;

  outline: none;
  border: none;
  background-color: transparent;

  font-size: $unfocused-font-size;
  color: white;

  transition: linear 100ms;

  &:focus{
    font-size: 1.1rem;
  }
}