.price-list {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 1rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .5rem;
}