.logo {
  width: auto;
  height: 100%;

  display: block;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: contain;
}