@import '../../../assets/style/input/file/file-input';
@import 'assets/style/variables/_border.scss';

.file-picker {
  @include file-picker();
  .input-wrapper {
    @include file-picker-input-wrapper();
  }

  .selected-file-container {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .image-previev {
      overflow: hidden;
      width: 100%;
      height: 10rem;

      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include balck-border(2px); 
      border-radius: $border-radious;
    }

    .file-name {
      margin: .5rem;
      font-size: .9rem;
      text-align: center;

      span {
        font-weight: bold;
      }
    }
  }
}