@import 'assets/style/variables/_color.scss';
@import 'assets/style/variables/_shadow.scss';
@import 'assets/style/variables/_dimension.scss';

.section {
  width: 100%;
  height: 75vh;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  border-top: 2px solid black;
  border-bottom: 2px solid black;

  text-decoration: none !important;

  transition: linear 100ms;
  animation-duration: 1s;

  @media (max-width: $mobile-width) {
    animation-name: scale-section-down;
  }

  @media (min-width: $mobile-width) {
    width: 18vw;
    height: 50vh;

    border: 2px solid $gray;

    animation-name: scale-section-up;

    &:hover {
      scale: 1.025;
      @include main-shadow();
    }
  }

  .section-title {
    width: 100%;
    padding: .5rem;
    margin: 3rem;

    background-color: $gray;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    @include main-shadow();

    font-size: 2.5rem;
    text-decoration: none !important;
    text-align: center;
    color: white;
  }
}

@keyframes scale-section-down {
  from {scale: 1.2;}
  to {scale: 1;}
}

@keyframes scale-section-up {
  from {scale: .8;}
  to {scale: 1;}
}