@import '../variables/border';
@import '../variables/color';

@mixin main-button() {
  cursor: pointer;
  
  padding: 1rem;

  border-radius: $border-radious;
  @include white-border(1px);
  outline: none;
  background-color: $gray;

  color: white;
  font-size: 1.45rem;
  font-weight: bold;

  transition: linear 100ms;

  &:hover {
    background-color: $light-gray;
    -webkit-box-shadow:  0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow:  0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow:  0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  }
}