.login-page {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-image: url('../../../assets/images/background/midnightBikeBackground.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}