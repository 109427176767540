@import 'assets/style/variables/color';
@import 'assets/style/variables/_dimension.scss';

.section-page {
  width: 100%;
  min-height: 100vh;
  padding-top: $admin-header-size;
  padding-bottom: 3rem;

  background-color: $black;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.5rem;
}