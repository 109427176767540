@import 'assets/style/variables/_dimension.scss';

.title {
  position: relative;

  width: 100%;
  height: 30vh;
  margin-bottom: 2rem;
  margin-top: 1rem;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  text-align: center;

  transition: linear 100ms;

  animation-name: opacity-up;
  transition: linear 100ms;
  animation-duration: 500ms;

  @media (min-width: $mobile-width) {
    height: 45vh;
  }

  .title__title {
    position: absolute;
    bottom: -2rem;
    left: 50%;
    transform: translate(-50%, 0);

    margin: 0;
    padding: .5rem;
    font-size: 3rem;
    background-color: white;

    animation-name: scale-section-down;
    transition: linear 100ms;
    animation-duration: 500ms;
  }
}

@keyframes scale-section-down {
  from {font-size: 2.5rem;}
  to {font-size: 3rem;}
}

@keyframes opacity-up {
  from {
    opacity: .7;
    background-color: black;
  }
  to {
    opacity: 1;
    background-color: transparent;
  }
}