@import 'assets/style/variables/_dimension.scss';
@import 'assets/style/variables/_z-index.scss';
@import 'assets/style/variables/_color.scss';

.header {
  z-index: $header-z-index;
  position: fixed;
  top: 0 !important;
  left: 0 !important;

  width: 100vw;
  height: $header-height;
  padding: .5rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  transition: linear 150ms;

  background-color: rgba(255, 255, 255, 1);

  .header__logo {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);

    height: calc($header-height + 3rem);
    width: 15rem;
    
    transition: linear 150ms;
  }

  .header__links {
    display: none;
  }

  @media (min-width: $mobile-width) {
    .header__links {
      position: absolute;
      top: 0;
      padding: 0 1rem;
  
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 1.5rem;
  
      white-space: nowrap;
      height: calc($header-height / 2);
    }
  
    .header__links--left {
      right: 100%;
    }
  
    .header__links--right {
      left: 100%;
    }
  }

  .header__contact {
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 1.5rem .4rem .4rem 1.5rem;
    background-color: white;

    @media (max-width: 1200px) {
      display: none;
    }

    .header__contact-img {
      width: 2.75rem;
      height: 2.75rem;
      padding: .5rem;

      border-radius: 50%;
      background-color: $orange;
    }

    .header__contact-text {
      padding: .25rem .5rem;
      font-size: 1.25rem;
      font-weight: bold;
      color: $orange;
    }
  }
 
}

.header--scrolled {
  height: $header-height-scroll;

  background-color: white;

  -webkit-box-shadow: 0px 3px 14px -5px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 3px 14px -5px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 3px 14px -5px rgba(0, 0, 0, 0.7);

  .header__logo {
    width: 12rem;
    height: calc($header-height-scroll + 2rem);
  }
}