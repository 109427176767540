@import 'assets/style/variables/dimension';

.password-form {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .75rem;

  @media (min-width: $mobile-width) {
    width: 30vw;
  }
}